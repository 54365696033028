import { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import headerImg from "../assets/img/header-img.svg"

export const Banner = () => {

	const [loopNum, setLoopNum] = useState(0)
	const [isDeleting, setIsDeleting] = useState(false)
	const toRotate = ["Web Programcısı", "Veri Analisti", "GSB Kamp Lideri"]
	const [text, setText] = useState('')
	const [delta, setDelta] = useState(300 - Math.random() * 100)
	const period = 2000

	useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		}, delta)

		return () => { clearInterval(ticker) };
	}, [text])

	const tick = () => {
		let i = loopNum % toRotate.length;
		let fullText = toRotate[i];
		let updateText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1)

		setText(updateText)

		if (isDeleting) {
			setDelta(prevDelta => prevDelta / 2)
		}

		if (!isDeleting && updateText === fullText) {
			setIsDeleting(true)
			setDelta(period)
		} else if (isDeleting && updateText === '') {
			setIsDeleting(false);
			setLoopNum(loopNum + 1);
			setDelta(500);
		}
	}

	return (
		<section className='banner' id="home">
			<Container>
				<Row className='align-items-center'>
					<Col xs={12} md={6} xl={7}>
						<span className='tagline'>Hoşgeldiniz, Benim Portföyüme!</span>
						<h1>{`Merhaba Ben`}< br />{`Davut Enes Yılmaz `}<br /><span className='wrap'>{text}</span></h1>
						<p>
							Merhaba, ben Davut Enes Yılmaz. Düzce Üniversitesi Bilgisayar Mühendisliği son sınıf öğrencisiyim. Bilgisayar ağları ve uygulama geliştirme konularına olan uzun süredir devam eden ilgim, bu platformda sizlerle paylaşmak için sabırsızlanıyorum.
							<br />
							<br />
							20 yaşındayım ve özellikle NoSQL, Node.js, Python, yapay zeka, backend geliştirme ve gömülü sistemler konularına derin bir ilgi duyuyorum. Burada edindiğim bilgi ve deneyimleri projelerimle sizlere aktarmak benim için büyük bir motivasyon kaynağı.
						</p>
						<a href='#connect'>
							<button>İletişim Kurabiliriz<ArrowRightCircle size={25} /></button>
						</a>
					</Col>
					<Col xs={12} md={6} xl={5}>
						<img src={headerImg} alt="Header Img" />
					</Col>
				</Row>
			</Container>

		</section>
	)
}
