import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

	const projects = [
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg1,
		},
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg2,
		},
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg3,
		},
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg1,
		},
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg2,
		},
		{
			title: "Business Startup",
			description: "Design & Development",
			imgUrl: projImg3,
		},
	];

	return (
		<section className="project" id="projects">
			<Container>
				<Row>
					<Col size={12}>
						<TrackVisibility>
							{({ isVisible }) =>
								<div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
									<h2>Portföy</h2>
									<p>Burada, yapmış olduğum çeşitli projeleri ve gönüllülük çalışmalarını sizinle paylaşıyorum. Her biri benim deneyim ve yeteneklerim hakkında birer öykü taşıyor.</p>
									<Tab.Container id="projects-tabs" defaultActiveKey="first">
										<Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
											<Nav.Item>
												<Nav.Link eventKey="first">Teknoloji Projeleri</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="second">Sosyal Sorumluluk Projeleri</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="third">Hakkında</Nav.Link>
											</Nav.Item>
										</Nav>
										<Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
											<Tab.Pane eventKey="first">
												<Row>
													{
														projects.map((project, index) => {
															return (
																<ProjectCard
																	key={index}
																	{...project}
																/>
															)
														})
													}
												</Row>
											</Tab.Pane>
											<Tab.Pane eventKey="second">
												<Row>
													{
														projects.map((project, index) => {
															return (
																<ProjectCard
																	key={index}
																	{...project}
																/>
															)
														})
													}
												</Row>
											</Tab.Pane>
											<Tab.Pane eventKey="third">
												<p>Merhaba, ben Davut Enes Yılmaz. Düzce Üniversitesi'nde Bilgisayar Mühendisliği son sınıf öğrencisiyim. Aynı zamanda 42 Kocaeli Anaokulu'nda öğrenciyim ve B sınıfı ehliyetim var. 20 yaşındayım ve teknolojiye olan ilgim çocukluğumdan beri devam ediyor. Bu ilgi, bilgisayar mühendisliği alanında daha fazla bilgi ve deneyim edinmemi sağlıyor..</p>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>}
						</TrackVisibility>
					</Col>
				</Row>
			</Container>
			<img className="background-image-right" src={colorSharp2}></img>
		</section>
	)
}
