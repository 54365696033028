import { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";

import logo from '../assets/img/logo.svg'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'

export const NavBar = () => {
	const [activeLink, setActiveLink] = useState('home');
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 50)
				setScrolled(true);
			else
				setScrolled(false);
		}
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [setScrolled])

	const onUpdateActiveLink = (value) => {
		setActiveLink(value);
	}

	return (
		<Router>
			<Navbar expand="md" className={scrolled ? "scrolled" : ""}>
				<Container>
					<Navbar.Brand href="/">
						<img src={logo} alt="Logo" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="navbar-toggler-icon"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Başlangıç</Nav.Link>
							<Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Yetenekler</Nav.Link>
							<Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Portföy</Nav.Link>
						</Nav>
						<span className="navbar-text">
							<div className="social-icon">
								<a href="https://www.linkedin.com/in/davut-enes-yilmaz/"><img src={navIcon1} alt="Linkedin: www.linkedin.com/in/davut-enes-yilmaz" /></a>
								<a href="https://www.facebook.com/umutlar123456789"><img src={navIcon2} alt="Facebook www.facebook.com/umutlar123456789" /></a>
								<a href="https://www.instagram.com/davut.enes4/"><img src={navIcon3} alt="Instagram www.instagram.com/davut.enes4" /></a>
							</div>
							<HashLink to='#connect'>
								<button className="vvd"><span>İletişim Kurabiliriz</span></button>
							</HashLink>
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Router>
	)
}
